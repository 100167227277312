<template>
    <div class="record">
        <h5header :name="$t('asset.desc8')" :right="false" />
        <div class="record-con">
            <van-list v-model:loading="loading" :loading-text="$t('quotes.desc20')" :finished="finished" @load="onLoad">
                <van-cell v-for="item in list" :key="item.id">
                    <div class="record-list flex" @click="handlerInfo(item)">
                        <img src="../../public/images/new/asseticon1.png" alt="" class="logo" />
                        <div class="list-info">
                            <div class="info-name flex">
                                <div>{{ $t('asset.desc14') }}</div>
                                <p>+{{ item.amount }} {{ item.coinName }}</p>
                            </div>
                            <div class="info-time flex">
                                <div>{{ item.date }}</div>
                                <div>{{ item.status }}</div>
                            </div>
                        </div>
                        <img src="../../public/images/new/righticon3.png" alt="" class="left" />
                    </div>
                </van-cell>
            </van-list>
            <div class="no-data" v-if="showNull">
                    <img src="../../public/images/no-data.png" alt="">
                    <span>{{ $t('record.desc13') }}</span>
                </div>
        </div>
    </div>
</template>

<script>
import h5header from '@/componets/h5header.vue';
export default {
    components: {
        h5header
    },
    data() {
        return {
            list: [],
            loading: false,
            finished: false,
            page: 1,
            showNull:false
        }
    },
    methods: {
        onLoad() {
            setTimeout(() => {
                this.$post(this.URL.assets.rechargeRecord, {
                    page: this.page,
                    pageSize: 10,
                    coinId: this.$route.query.id
                }).then(res => {
                    if (res.code == 0) {
                        if (this.page == 1) {
                            this.list = res.data.list
                        } else {
                            this.list = this.list.concat(res.data.list)
                        }
                        this.page++;
                        this.loading = false;
                        if(this.list.length==0){
              this.showNull=true;
            }
                        if (this.list.length >= parseFloat(res.data.page.count)) {
                            this.finished = true;
                        }

                    }
                })
            }, 500);
        },
        handlerInfo(info) {
            localStorage.setItem('recordinfo',JSON.stringify(info))
                this.$router.push('/record/info')
        }
    }
}
</script>

<style lang="less" scoped>
.record {
    padding: 0 16px;
    min-height: 100vh;
    background: url('../../public/images/new/h5bg.png') center no-repeat;
    background-size: 100% 100%;
}
.no-data {
        width: 100%;
        padding: 130px 0;

        img {
            display: block;
            width: 120px;
            height: 120px;
            margin: 0 auto 16px;
        }

        span {
            display: block;
            margin: 0 auto;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #A1A1A1;
        }
    }

.record-con {
    padding: 56px 0;
    ::v-deep .van-list{
        border-radius: 8px;
        background: #181818;
        padding: 0 14px;
        .van-cell{
            padding: 15px 0;
            background:none;
            &::after{
                border-bottom:1px solid rgba(255, 255, 255, 0.06);
            }
            .record-list{
                .logo{
                    flex: 0 0 36px;
                    width: 36px;
                    height: 36px;
                    margin-right: 8px;
                }
                .left{
                    flex: 0 0 4px;
                    width: 4px;
                    height: 6px;
                    margin: 15px 0 0 9px;
                }
                .list-info{
                    flex: 1;
                    .info-name{
                        margin-bottom: 8px; 
                        justify-content: space-between;
                        font-size: 16px;
                        color: #fff;
                        line-height: 16px;
                    }
                    .info-time{
                        font-size: 12px;
                        color: #BBBBBB;
                        justify-content: space-between;
                        line-height: 12px;
                    }
                }
            }
        }
    }
}
</style>